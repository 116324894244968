import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import { compile } from "path-to-regexp";
import _isEqual from "lodash.isequal";
import { useValidateQueryParams } from "@ugg/shared/query-params/params-helpers";
import { buildQueryParams } from "@ugg/shared/utils/url-query-params";
import { useQueryString } from "@ugg/shared/hooks/use-query-string";

import { FilterOption } from "@ugg/shared/interfaces/filter-manager.interface";

export const FILTER_IS_NOT_DEFAULT_CLASSNAME = "filter_not-default";

export interface FilterManagerHookResults {
  filters: Record<string, any>;
  getNewFilters: (
    filterType: string,
    filterOption: FilterOption | null,
  ) => {
    filters: { [key: string]: any };
    path: string;
  };
  onFilterChange: (filterType: string, filterOption: FilterOption | null) => void;
  isDefault?: boolean | ((filterKey: string) => boolean);
  updateQueryParams: (newFilter: Record<string, any>, pathname?: string) => void;
}

export function useFilterManager<T = Record<string, any>>(
  page: keyof T,
  validParams: T,
  options?: {
    pathParameters?: { [key: string]: string };
  },
): FilterManagerHookResults {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryString();

  const { pathParameters } = options || {};

  const validateQueryParams = useValidateQueryParams(validParams);

  const updateQueryParams = (newFilter: Record<string, any>, pathname?: string) => {
    if (pathname !== location.pathname || !_isEqual(queryParams, newFilter)) {
      history.replace({
        pathname: pathname || location.pathname,
        search: buildQueryParams(newFilter),
      });
    }
  };

  const getNewFilters = (filterType: string, filterValue: FilterOption | null) => {
    let newFilter: { [key: string]: any } = {};
    let newPath: string = location.pathname;

    if (filterType && filterValue) {
      newFilter[filterType] = filterValue.value;
    }

    newFilter = { ...queryParams, ...newFilter };
    if (filterType && filterValue === null) {
      delete newFilter[filterType];
    }
    newFilter = validateQueryParams(page, newFilter);

    if (filterValue?.reset) {
      filterValue?.reset.forEach((param) => delete newFilter[param]);
    }

    if (filterType && filterValue?.path) {
      if (filterValue?.validParamsPage) {
        newFilter = validateQueryParams(filterValue.validParamsPage as keyof T, newFilter, false);
      }
      const toPath = compile(filterValue.path, { encode: encodeURIComponent });
      if (filterValue?.pathParameters && !pathParameters) {
        console.error(
          `'pathParameters' object missing. Either set pathParameters to false in your filter options or provide pathParameters object`,
        );
      }

      newPath = toPath(pathParameters || {});
    }

    return { filters: newFilter, path: newPath };
  };

  const onFilterChange = (filterType: string, filterValue: FilterOption | null) => {
    const { filters, path } = getNewFilters(filterType, filterValue);
    updateQueryParams(filters, path);

    // if (filterType && filterValue) {
    //   newFilter[filterType] = filterValue.value;

    //   if (window.dataLayer) {
    //     window.dataLayer.push({
    //       event: "filter-select",
    //       category: "filter",
    //       filterType: filterType,
    //       filterValue: filterValue.value,
    //       page: page,
    //     });
    //   }
    // }
  };

  const validPageParams: Record<string, any> = validParams[page] || {};
  const defaultParams: Record<string, any> = validPageParams.default || {};

  const validatedQueryParams = validateQueryParams(page, queryParams, true);
  const filters = {
    ...defaultParams,
    ...validatedQueryParams,
  };

  const isDefault = (filterKey: string) => {
    return (filters && filters[filterKey]) === (defaultParams && defaultParams[filterKey]);
  };

  return {
    filters,
    getNewFilters,
    onFilterChange,
    isDefault,
    updateQueryParams,
  };
}

export function FilterManagerWrapper(props: { className?: string; children: React.ReactNode | React.ReactNode[] }) {
  return (
    <div className={classNames("filter-manager", props.className)}>
      {/* <div className="filter-width-wrapper"> */}
      <div className="filter-manager_container">{props.children}</div>
      {/* </div> */}
    </div>
  );
}
